import Axios, { AxiosResponse, AxiosStatic } from 'axios';

const CONNECT_API = process.env.REACT_APP_CONNECT_API;
const CONNECT_API_KEY = process.env.REACT_APP_CONNECT_API_KEY;
export const AXIOS_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: CONNECT_API_KEY,
  },
};

export default class ConnectServicesClient {
  constructor(private axios: AxiosStatic = Axios) {}

  async uploadMedia(
    preInspectionsId: string,
    filename: string,
    fileExtension: string,
    file: File,
    metadataTag?: string,
  ): Promise<any> {
    const folderRoot = process.env.REACT_APP_DEFAULT_MEDIA_FOLDER ?? 'Default';
    const formData = new FormData();
    formData.append(
      'FolderRoot',
      `preinspections/${folderRoot}/${preInspectionsId}`,
    );
    formData.append('FileDataStream', file);
    formData.append('FileName', filename);
    formData.append('FileExtension', fileExtension);
    formData.append('CountryCode', String(process.env.REACT_APP_COUNTRY_CODE));

    if (metadataTag && metadataTag.length > 0) {
      formData.append('MetadataTag', metadataTag);
    }

    return await this.axios.post(`${CONNECT_API}/api/v1/media`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  async uploadMediaBase64(
    preInspectionsId: string,
    fileName: string,
    fileExtension: string,
    fileBase64: string,
    metadataTag?: string,
  ): Promise<any> {
    const folderRoot = process.env.REACT_APP_DEFAULT_MEDIA_FOLDER ?? 'Default';
    const formData = new FormData();
    formData.append(
      'FolderRoot',
      `preinspections/${folderRoot}/${preInspectionsId}`,
    );
    formData.append('FileDataBase64', fileBase64);
    formData.append('FileName', fileName);
    formData.append('FileExtension', fileExtension);

    if (metadataTag && metadataTag.length > 0) {
      formData.append('MetadataTag', metadataTag);
    }

    return await this.axios.post(`${CONNECT_API}/api/v1/media`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async deleteImage(resource: string): Promise<AxiosResponse> {
    return await this.axios.post(`${CONNECT_API}/api/v1/media/remove`, {
      fileURL: resource,
    });
  }

  async getLocalization(countryCode: string): Promise<AxiosResponse> {
    return await this.axios.get(
      `${CONNECT_API}/api/v1/catalogs/provinces?code=${countryCode}`,
    );
  }

  async getPreInspectionByParams(
    uniquePreEventId: string,
  ): Promise<AxiosResponse> {
    return await this.axios.get(
      `${CONNECT_API}/api/v1/preinspections/${process.env.REACT_APP_PARTNER_API_KEY}/${uniquePreEventId}`,
    );
  }

  async getFormByParams(uniquePreEventId: string): Promise<AxiosResponse> {
    const dataObj = {
      uniquePreEventId: uniquePreEventId,
      companyApiKey: process.env.REACT_APP_PARTNER_API_KEY,
      applicationId: 1, // SIC
      rolId: 1, // Admin rol
      formType: 5, //PreInspection-link form
    };

    return await this.axios.post(`${CONNECT_API}/api/v1/forms`, dataObj);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async addOrEditPreInspect(preInspectObj: any): Promise<AxiosResponse> {
    return await this.axios.post(
      `${CONNECT_API}/api/v1/preinspections`,
      preInspectObj,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  }

  async getMedia(preInspectionsId: string): Promise<any> {
    let folderRoot = process.env.REACT_APP_DEFAULT_MEDIA_FOLDER ?? 'Default';
    folderRoot = encodeURIComponent(
      `preinspections/${folderRoot}/${preInspectionsId.toLowerCase()}`,
    );

    return await this.axios.post(`${CONNECT_API}/api/v1/media/resources`, {
      folderPath: folderRoot,
    });
  }

  async getPreInspectionFinishStatus(
    preinspectId: number,
  ): Promise<AxiosResponse> {
    return await this.axios.get(
      `${CONNECT_API}/api/v1/preinspections/finish/${preinspectId}`,
      AXIOS_CONFIG,
    );
  }

  async sharedPreInspection(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
  ): Promise<AxiosResponse> {
    return await this.axios.post(
      `${CONNECT_API}/api/v1/preinspections/shared`,
      data,
      AXIOS_CONFIG,
    );
  }

  async getIsValidZones(preInspectId: number): Promise<AxiosResponse> {
    return await this.axios.get(
      `${CONNECT_API}/api/v1/preinspections/validzones/${preInspectId}`,
      AXIOS_CONFIG,
    );
  }
}

export const CONNECT_SERVICES_CLIENT = new ConnectServicesClient();
